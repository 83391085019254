import { computed, type Ref } from "vue"
import userAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"
import { useStore } from "@/ContextTab/useStore"
import type { NewsStore } from "@/ContextApp/stores/news"
import { REFERENCES } from "@/lib/references"

export default function useNewsItem(id: Ref<number | string>) {
  const store = useStore<NewsStore>("news", { shared: true })

  const newsItem = computed(() => store.data.news?.[id.value] ?? null)
  const version = computed(() => newsItem.value?.version ?? null)

  const { userId } = userAuthInfo()

  const newsItemIsRead = computed(() => {
    if (newsItem.value && newsItem.value.users.length) {
      return !!newsItem.value.users.find(
        (user: any) => user.user.id === userId.value && user.is_read
      )
    }
    return false
  })

  const newsItemIsScheduled = computed(() => {
    if (version.value) {
      return version.value.publish_at !== null || version.value.is_postponed
    }
    return false
  })

  const newsItemIsExpired = computed(() => {
    return store.data.expired?.includes(id.value)
  })

  const newsItemUserCanWorkIds = computed(() => {
    return (
      newsItem.value?.users
        ?.filter((binding: any) => binding.can_work)
        .map(({ user }: any = {}) => user.id) ?? []
    )
  })

  const newsItemIsAssignedToMe = computed(() => {
    if (version.value) {
      const statusName =
        REFERENCES.News.Status[version.value.status_id].name
      if (
        ["WAITS_FOR_EDIT"].includes(
          statusName
        )
      ) {
        return Boolean(
          newsItemUserCanWorkIds.value.find(
            (id: string | boolean) => id === userId.value
          )
        )
      }
    }
    return false
  })

  const newsItemIsReadyToEdit = computed(() => {
    const statusName = REFERENCES.News.Status[version.value.status_id].name
    return ["WAITS_FOR_EDIT"].includes(statusName)
  })

  const newsItemIsRevoked = computed(() => newsItem.value?.is_revoked ?? false)

  const setNewsItemAsRead = () => {
    const statusName = REFERENCES.News.Status[version.value.status_id].name
    if (
      !["WAITS_FOR_EDIT"].includes(
        statusName
      ) ||
      newsItemIsRead.value
    ) {
      return
    }
    store.setNewsItemAsRead(id.value)
  }

  return {
    newsItemIsRead,
    newsItemIsScheduled,
    newsItemIsExpired,
    newsItemIsAssignedToMe,
    newsItemIsReadyToEdit,
    newsItemIsRevoked,

    setNewsItemAsRead,
  }
}
