import { computed, type Ref } from "vue"
import userAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"
import { useStore } from "@/ContextTab/useStore"
import { formatUsername } from "@/utils/formatUsername"
import useNewsItem from "./useNewsItem"
import type { NewsStore } from "@/ContextApp/stores/news"
import { NEWS_TYPES_BY_NAME } from "@/lib/referencesByName"
import { REFERENCES } from "@/lib/references"

export default function useNewsItemVersion(newsItemId: Ref<number | string>) {
  const store = useStore<NewsStore>("news", { shared: true })

  const version = computed(() => store.data.news?.[newsItemId.value]?.version)

  const { userId } = userAuthInfo()

  const { newsItemIsScheduled, newsItemIsRevoked } = useNewsItem(newsItemId)

  const getVersionUserName = computed(
    () =>
      formatUsername(version.value?.user?.name) ||
      version.value?.recipient_group?.name ||
      formatUsername(version.value?.recipient_user?.name) ||
      ""
  )

  const getVersionAuthorsNames = computed(() =>
    (version.value?.authors ?? [])
      .map(({ user } = {}) => formatUsername(user.name))
  )

  const getVersionStatusDescription = computed(() => {
    const status = REFERENCES.News.Status[version.value?.status_id]
    if (!status || !status.name) return ""

    const userName = getVersionUserName.value
    const withUser = (status: any, user: any) =>
      status + (user ? ": " : "") + user

    switch (status.name) {
      case "DRAFT": // 1
        return withUser("В работе", userName)
      case "WAITS_FOR_EDIT": // 2
      case "WAITS_FOR_REWORK": // 4
      case "WAITS_FOR_REEDIT": // 5
      case "ON_EDIT": // 3
      case "ON_REWORK": // 6
        return withUser(status.description, userName)
      case "WAITS_FOR_PUBLICATION": // 8
        return newsItemIsScheduled.value ? "Отложено" : status.description
      case "PUBLISHED": // 10
        return newsItemIsRevoked.value ? "Аннулирована" : status.description
      default:
        return status.description
    }
  })

  const isVersionOfTypeFlash = computed(
    () => version.value?.type_id === NEWS_TYPES_BY_NAME["FLASH"].id
  )

  const isVersionOfTypeExpress = computed(
    () => version.value?.type_id === NEWS_TYPES_BY_NAME["EXPRESS"].id
  )

  const getIsMyNewsVersion = computed(() =>
    version.value?.user?.id
      ? version.value.user.id === userId.value
      : false
  )

  return {
    version,
    getVersionAuthorsNames,
    isVersionOfTypeFlash,
    isVersionOfTypeExpress,
    getIsMyNewsVersion,
    getVersionStatusDescription,
  }
}
