<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import CodesPopover from "@/ContextTab/modules/news/codes/components/CodesPopover.vue"
import NewsCodes from "@/ContextTab/modules/news/codes/components/NewsCodes.vue"
import { ref, computed, reactive, watch } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"
import type { NewsStore } from "@/ContextApp/stores/news"
import { REFERENCES } from "@/lib/references"

type NewsItemPublished = any
const props = withDefaults(
  defineProps<{
    item: NewsItemPublished
    isReleased: boolean
  }>(),
  {}
)

const emit = defineEmits([
  "republish",
  "open",
  "extend",
  "checkedCodeUpdate",
  "codeSubmit",
  "onTogglePopover",
])

const newsStore = useStore<NewsStore>("news", {
  shared: true,
})

// TODO: изменить, когда будут добавлены цветовые схемы
const btnColor = ref("#0069e4")

const showPopover = ref(false)
const loading = ref(false)
const message = ref<Message | null>(null)

const newsItemCodeIds = computed(
  () => props.item?.version?.codes?.map(({ code }) => code.id)
)

const newsLanguage = computed(
  () =>
    REFERENCES.News.Language[
      props.item?.version?.language_id
    ] ?? "RU"
)

watch(showPopover, next => {
  emit("onTogglePopover", next)
})

type CheckedState = { [key: string]: any }
const checkedState = reactive<CheckedState>({})

const onCheckedUpdate = (state: CheckedState) => {
  Object.assign(checkedState, state)
}

const republishUneditedNewsItem = async () => {
  const ids = Object.keys(checkedState).filter(
    codeId => checkedState[codeId]?.state === "included"
  )
  if (newsStore.created) {
    return newsStore.republishUnedited(props.item.id, ids)
  }
  return false
}

const onSubmit = async () => {
  loading.value = true
  const result = await republishUneditedNewsItem()
  message.value = result.payload.message
  showPopover.value = false
  loading.value = false
}
</script>

<template>
  <div class="actions-block">
    <PsTooltip content="Повтор" placement="top">
      <PsButton
        v-if="isReleased"
        circle
        :border="false"
        class="action-button"
        @click.stop="emit('republish')"
      >
        <PsIcon name="button-icon-publish" :size="24" :color="btnColor" />
      </PsButton>
    </PsTooltip>
    <template v-if="isReleased">
      <CodesPopover
        :loading="loading"
        :open="showPopover"
        @cancel="showPopover = false"
        @submit="onSubmit"
      >
        <PsTooltip content="Добавить коды" placement="top">
          <PsButton
            circle
            :border="false"
            class="action-button"
            @click.stop="showPopover = true"
          >
            <PsIcon name="button-icon-add" :size="24" :color="btnColor" />
          </PsButton>
        </PsTooltip>
        <template #main>
          <NewsCodes
            :omit="newsItemCodeIds"
            profileName="PUBLISH_NEWS"
            :lang="newsLanguage?.name"
            @update="onCheckedUpdate"
          />
        </template>
      </CodesPopover>
    </template>

    <PsTooltip content="Расширить" placement="top">
      <PsButton
        circle
        :border="false"
        class="action-button"
        @click.stop="emit('extend')"
      >
        <PsIcon name="button-icon-extend" :size="24" :color="btnColor" />
      </PsButton>
    </PsTooltip>
    <PsTooltip content="Открыть в новой вкладке" placement="top">
      <PsButton
        circle
        :border="false"
        class="action-button"
        @click.stop="emit('open')"
      >
        <PsIcon name="button-icon-show-more" :size="24" :color="btnColor" />
      </PsButton>
    </PsTooltip>
  </div>
</template>

<style lang="postcss" scoped>
.actions-block {
  --padding: 60px;

  display: flex;
  position: absolute;
  bottom: 1.25em;
  right: 0.75em;
  z-index: 10;
  padding-left: var(--padding);
  gap: 0.125rem;

  .action-button {
    --button--hover--background: hsl(223, 84%, 85%);
  }
}

.published-news-list-item .actions-block {
  background: linear-gradient(
    to right,
    transparent,
    rgba(232, 238, 253, 0.7) calc(var(--padding) - 20px)
  );
}
</style>
