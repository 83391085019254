import { useStore } from "@/ContextTab/useStore"
import type { NewsStore } from "@/ContextApp/stores/news"
import { NEWS_STATUSES_BY_NAME } from "@/lib/referencesByName"

export default function () {
  const newsStore = useStore<NewsStore>("news", {
    shared: true,
  })

  // // --------------------------------------------------------------
  // //                           Getters
  // // --------------------------------------------------------------

  type ChildStatusGetter = (child: any) => boolean

  const childStatusPublished: ChildStatusGetter = child => {
    return child.is_published
  }

  const childStatusWaitForEdit: ChildStatusGetter = child => {
    return (
      newsStore.data.news?.[child.news_id]?.version?.status_id ===
      NEWS_STATUSES_BY_NAME["WAITS_FOR_EDIT"].id
    )
  }

  const childStatusWaitForPublication: ChildStatusGetter = child => {
    return (
      newsStore.data.news?.[child.news_id]?.version?.status_id ===
      NEWS_STATUSES_BY_NAME["WAITS_FOR_PUBLICATION"].id
    )
  }

  const childStatusWaiting: ChildStatusGetter = child =>
    childStatusWaitForEdit(child) ||
    childStatusWaitForPublication(child)

  const getRelatedUsers = newsItem => {
    if (newsItem?.groups && newsItem?.users) {
      const groups = newsItem.groups.map(({ group }) => group.code)
      const users = newsItem.users
        .filter(user => user.can_work)
        .map(({ user }) => user.code)

      return [...groups, ...users].join(", ")
    }

    return ""
  }

  const userData = (child: any) => {
    const item = newsStore.data.news?.[child.news_id]

    if (childStatusPublished(child) || childStatusWaitForPublication(child)) {
      return item.publisher?.code
    }

    if (childStatusWaiting(child)) {
      return getRelatedUsers(item)
    }

    return item?.version?.user?.code
  }

  const childLoaded = child => {
    return !!newsStore.data.news?.[child.news_id]
  }

  return {
    childLoaded,
    childStatusPublished,
    childStatusWaitForEdit,
    childStatusWaitForPublication,
    userData,
  }
}
