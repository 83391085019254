<script setup lang="ts">
import PublishedPreviewToolbar from "./PublishedPreviewToolbar.vue"
import Panel from "@/ContextTab/components/Panel/Panel.vue"
import PanelToolbar from "@/ContextTab/components/Toolbar/PanelToolbar.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsDropdown from "@/ContextTab/components/UI/PsDropdown.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import WithInplaceMessage from "@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue"
import PublishedItemView from "@/ContextTab/modules/news/published/components/PublishedItemView.vue"
import PublishedNewsHistory from "@/ContextTab/modules/news/published/components/PublishedNewsHistory.vue"
import RelationsNewsModal from "@/ContextTab/modules/news/published/components/RelationsNewsModal.vue"
import { useToggle } from "@vueuse/core"
import { computed, ref, watch } from "vue"
import useNewsActions from "@/ContextTab/modules/news/composables/useNewsActions"
import useShareModal from "@/ContextTab/modules/news/composables/useShareModal"
import { newsAction } from "@/ContextTab/modules/news/published/components/types"
import { useStore } from "@/ContextTab/useStore"
import type { Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"
import type { NewsStore } from "@/ContextApp/stores/news"
import type { AuthStore } from "@/ContextApp/stores/auth"
import { ACCESS } from "@/lib/accessCodes"
import type { SizeBreakpoints } from '@/ContextTab/composables/types'
import type { PsApiV2 } from "ps-client"
import { REFERENCES } from "@/lib/references"

const props = defineProps<{
  item: any
  mode: PsApiV2.PublishedPanelMode
  searchMode: boolean
}>()

const emit = defineEmits(["open", "revoke"])

const newsStore = useStore<NewsStore>("news", {
  shared: true,
})

const userAuth = useStore<AuthStore>("auth", {
  shared: true,
})

const loading = ref(false)
const message = ref<Message | null>(null)

const showCodesPopup = ref(false)
const showActionsMenu = ref(false)

const toggleActionsMenu = (show: boolean) => {
  showActionsMenu.value = show
  if (showActionsMenu.value && showCodesPopup.value) {
    showCodesPopup.value = false
  }
}

const toggleCodesPopup = (show: boolean) => {
  showCodesPopup.value = show
  if (showCodesPopup.value && showActionsMenu.value) {
    showActionsMenu.value = false
  }
}

const newsLanguage = computed(() => {
  return (
    REFERENCES.News.Language[
      props.item?.version?.language_id
    ] ?? "RU"
  )
})

async function republishUneditedNewsItem(ids: number[]) {
  if (newsStore.created) {
    return newsStore.republishUnedited(props.item.id, ids)
  }
  return false
}

async function onSubmit(ids: number[]) {
  loading.value = true
  const result = await republishUneditedNewsItem(ids)
  message.value = result.payload.message
  loading.value = false
}

const { extend, republish, revoke, open } = useNewsActions(loading, message)

const [newsActionDialogVisible, toggleNewsActionDialogVisible] = useToggle()
const [relationsDialogVisible, toggleRelationsDialogVisible] = useToggle()
const { showShareModal, message: shareMessage } = useShareModal()

function onSelectActionHandler(action: string) {
  switch (action as keyof typeof newsAction) {
    case "showHistory":
      toggleNewsActionDialogVisible(true)
      break
    case "revoke":
      revoke(props.item.id)
      break
    case "showRelations":
      toggleRelationsDialogVisible(true)
      break
    case "sendToAsoi":
      showShareModal({ itemId: props.item.id, actionType: "sendToAsoi" })
  }
}

const options = computed(() => [
    {
      label: newsAction.sendToAsoi.label,
      key: newsAction.sendToAsoi.key,
    },
    {
      label: newsAction.showHistory.label,
      key: newsAction.showHistory.key,
    },
    {
      label: newsAction.showRelations.label,
      key: newsAction.showRelations.key,
    },
    {
      label: newsAction.revoke.label,
      key: newsAction.revoke.key,
      show:
        props.item.publisher?.id === userAuth.data.tokenData?.sub ||
        userAuth.data.tokenData?.permissions.includes(ACCESS.NEWS.REVOKE),
    },
  ]
)

watch(shareMessage, next => {
  message.value = next
})

const toolbarBreakpoints: SizeBreakpoints = {
  "xs": 0,
  "sm": 280,
  "md": 390,
  "lg": 500,
  "xl": 1000,
  "xxl": 1200,
  "xxxl": 1800,
}
</script>

<template>
  <WithInplaceMessage :loading="loading" :message="message">
    <Panel
      class="preview-panel"
      background="#f2f2f2"
    >
      <template #titlebar>
        <PanelToolbar
          class="titlebar"
          :breakpoints="toolbarBreakpoints"
        >
          <template #left>
            <PublishedPreviewToolbar
              :item="item"
              :panelMode="mode"
              :newsLanguage="newsLanguage"
              :showCodesPopup="showCodesPopup"
              @extend="extend(item.id)"
              @republish="republish(item.id)"
              @toggleCodesPopup="toggleCodesPopup"
              @submit="onSubmit"
            />
          </template>

          <template #right>
            <PsTooltip content="Открыть в новой вкладке">
              <PsButton size="sm" @click="open(item.id)">
                <PbIcon name="open-out" />
              </PsButton>
            </PsTooltip>

            <PsDropdown
              trigger="manual"
              :visible="showActionsMenu"
              :options="options"
              @select="onSelectActionHandler"
              @clickOutside="toggleActionsMenu(false)"
            >
              <PsTooltip content="Действия с новостью">
                <PsButton size="sm" @click="toggleActionsMenu(!showActionsMenu)">
                  <PbIcon name="menu-burger" />
                </PsButton>
              </PsTooltip>
            </PsDropdown>
          </template>

          <PublishedNewsHistory
            v-if="newsActionDialogVisible && item.id"
            :id="item.id"
            v-model:show="newsActionDialogVisible"
          />

          <RelationsNewsModal
            v-model="relationsDialogVisible"
            :item="item"
            @open="emit('open', $event)"
          />
        </PanelToolbar>
      </template>

      <template #content>
        <div class="content">
          <PublishedItemView
            :newsItem="item"
            :version="item.version || {}"
            :searchMode="searchMode"
          />
        </div>
      </template>
    </Panel>
  </WithInplaceMessage>
</template>

<style lang="postcss" scoped>
.preview-panel {
  --toolbar-shadow: none;

  & ::v-deep(.panel-toolbar) {
    border-bottom: 1px solid #c9d4e1;
  }

  .titlebar {
    position: relative;
  }

  .content {
    overflow-y: auto;
  }
}
</style>
